.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal {
    background: #000000;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    max-width: 500px;
    width: 100%;
}

.fine {
    border: 1px solid #ffffff;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 5px;
}

.fine img {
    max-width: 100%;
    max-height: 150px;
    height: auto;
    display: block;
    margin-top: 10px;
}
